<template>
   <div>
      <v-container fluid>
         <v-row>
            <v-col v-for="(servicio, i) in servicios" :key="i" :cols="servicio.sm" :md="servicio.md" :lg="servicio.lg">
               <v-card :color="servicio.color" elevation="5" dark height="100%">
                  <v-card-title v-text="servicio.title"></v-card-title>
                  <v-card-text>{{ servicio.body }}</v-card-text>
               </v-card>
            </v-col>
         </v-row>
      </v-container>
   </div>
</template>

<script>
export default {
   name: "Servicios",
   data: () => ({
      servicios: [
         {
            title: "Reportes de Servicio",
            body: "Crea reportes de servicio, adjunta imágenes, y envíalos a tus clientes.",
            color: "red",
            sm: "12",
            md: "6",
            lg: "4",
         },
         {
            title: "Consultas inteligentes",
            body: "Realiza búsquedas personalizadas de los reportes de servicio y expórtalas",
            color: "pink",
            sm: "12",
            md: "6",
            lg: "4",
         },
         {
            title: "Clientes",
            body: "Guarda un catálogo de clientes con información útil para agilizar tus reportes",
            color: "deep-purple",
            sm: "12",
            md: "12",
            lg: "4",
         },
         {
            title: "Movimientos",
            body: "Vende paquetes de horas de servicio a tus clientes, nosotros nos encargamos del resto.",
            color: "blue",
            sm: "12",
            md: "12",
            lg: "12",
         },
         {
            title: "Usuarios",
            body: "Registra tus empleados y administra sus permisos para acceder a la plataforma.",
            color: "cyan darken-1",
            sm: "12",
            md: "6",
            lg: "6",
         },
         {
            title: "Personalización",
            body: "Configura la plataforma con las necesidades de tu negocio.",
            color: "light-green",
            sm: "12",
            md: "6",
            lg: "6",
         },
         {
            title: "Conectividad",
            body: "Envía reportes por SMS o correo a tus clientes para ser firmados",
            color: "orange",
            sm: "12",
            md: "12",
            lg: "12",
         },
      ],
   }),
};
</script>
